import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import moment from 'moment';
import {AsyncPaginate} from 'react-select-async-paginate';
import theme from '../../../theme';
import mapStateToRequest from '../../../helpers/mapStateToRequest';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import {DatePicker, LocalizationProvider} from '@material-ui/pickers';
import momentAdapter from '@material-ui/pickers/adapter/moment';
import {api_get, api_post, api_put} from '../../../utils/Api';
import LoadingApi from 'components/LoadingApi/LoadingApi';
import {getUsers, getOffers} from "../../../helpers/methods";


const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },

}));

const Form = (props) => {
  const {id} = useParams();
  const {className, edit, userId, ...rest} = props;
  const classes = useStyles();
  const [accounts, getAccounts] = useState([]);
  const [offices, getOffices] = useState([]);
  const [agencies, getAgencies] = useState([]);
  const [open, setOpen] = useState(false);
  const [openCheckModal, setOpenCheckModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [checkSliceNumber, setCheckSLiceNumber] = useState('');
  const [slicesValues, setSlicesValues] = useState({
    slices: '',
    totalamount: null,
    endDate: '',
    paymentAgreement: id || '',
  });
  const [values, setValues] = useState({
    user: userId || '',
    offer: '',
    quantity: '',
    active: '',
    amount: 0,
    slices: [],
    user_group: null,
    start_date: null,
    end_date: null,
  });
  const [checks, setChecks] = useState({});
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api_get(`company-accounts`).then((data) => {
      getAccounts(data.payload);
    });
    api_get(`offices`).then((data) => {
      getOffices(data.payload);
    });
    api_get(`agencies`).then((data) => {
      getAgencies(data.payload);
    });
    if (edit) {
      setLoading(true)
      api_get(`payment-agreements/${id}`).then((result) => {
        setLoading(false);
        const data = result.payload;
        setValues({
          user: data?.user,
          amount: data?.amount,
          offer: data?.offer,
          slices: data?.payment_agreement_slices,
          active: data?.active === true ? '1' : '0',
          quantity: data?.quantity,
          start_date: data?.start_date,
          end_date: data?.end_date,
        });
      });
    }
  }, []);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleCheckChange = (event, value) => {
    setChecks({
      ...checks,
      [event.target.name]: event.target.value,
    });
  };
  const handleFieldsChange = (event, index) => {
    const newArr = {...values};
    newArr.slices[index][event.target.name] = event.target.value;
    setValues({...newArr});
  };
  const handleSliceCheckChange = (event, index, id) => {
    const newArr = {...values};
    newArr.slices[index][event.target.name] = event.target.checked ? true : false;
    setValues({...newArr});
    if (id !== null && event.target.checked === false) {
      setOpenDeleteModal(true);
      setCheckSLiceNumber(index);
    }
    if (event.target.name == 'is_check' && event.target.checked) {
      setOpenCheckModal(true);
      setCheckSLiceNumber(index);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
    }
  const dateChange = (event) => {
    if (event.target.value) {
      setChecks({...checks, 'check_date': event.target.value});
    }
  };
  const updateCheck = (event, index) => {
    setCheckSLiceNumber(index);
    setOpenCheckModal(true);
    setChecks(values.slices[index]['checks']);
  };
  const handleSlicesChange = (event) => {
    setSlicesValues({
      ...slicesValues,
      [event.target.name]: event.target.value,
    });
  };
  const startDateChange = (value) => {
      setValues({...values, start_date: value ? value.format('YYYY-MM-DD hh:mm') : null});
  };

  const endDateChange = (value) => {
      setValues({...values, end_date: value ? value.format('YYYY-MM-DD hh:mm') : null});
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const AddSlices = () => {
    api_post(`payment-agreements/decompose-amount-to-slice`, slicesValues).
        then((result) => {
          if (result.errors) {
            showErrorMessage(result.errors);
          } else {
            values.slices = result;
            values.amount = slicesValues.totalamount;
            setValues({
              ...values,
              ['slices']: Object.values(result) ? Object.values(result) : null,
            });
          }
        }).
        catch((error) => {
          throw new Error(error);
        });
  };
  const handleSubmitCheck = (event, sliceIndex) => {
    api_post(`slice-check`, checks).then((result) => {
      if (result.errors) {
        showErrorMessage(result.errors);
      } else {
        setAlertSeverity('success');
        setAlertMessage(result.message);
        setOpen(true);
        const newArr = values;
        newArr.slices[sliceIndex]['checks'] = checks;
        setValues({...newArr});
        setOpenCheckModal(false);
      }
    });
  };
  const handleCheckClose = () => {
    setChecks({});
    setOpenCheckModal(false);
  };

  const offerChange = (value) => {
    setValues({...values, offer: value});
  };
  const userChange = (value) => {
    setValues({...values, user: value});
  };
  const formValidation = (values) => {
    if (!values?.amount && !edit) {
      return { success: false, 'message': 'Amount is missing' };
    }
    if (!values?.user) {
      return { success: false, 'message': 'User is missing' };
    }
    if (!values?.offer) {
      return { success: false, 'message': 'Offer is missing' };
    }
  /*  if (!values?.quantity) {
      return { success: false, 'message': 'Quantity is missing' };
    }*/
  };
  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity('warning');
      setOpen(true);
    }
    else {
      const requestValues = mapStateToRequest(values, ['slices'], ['active']);
      setLoading(true);
      if (edit) {
        api_put(`payment-agreements/${id}`, requestValues).then((result) => {
          if (result.errors) {
            showErrorMessage(result.errors);
          } else {
            setAlertSeverity('success');
            setAlertMessage('payment agreement updated successfully');
            setOpen(true);
            setLoading(false);
            window.location.href = '/finance/payment-agreements';
          }
        });
      } else {
        api_post(`payment-agreements`, requestValues).then((result) => {
          if (result.errors) {
            showErrorMessage(result.errors);
          } else {
            setAlertSeverity('success');
            setAlertMessage('payment agreement created successfully');
            setOpen(true);
            setLoading(false);
            window.location.href = '/finance/payment-agreements';
          }
        }).catch((error) => {
          throw new Error(error);
        });
      }
    }
  };

  return (
      <div>
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
          <form
              autoComplete="off"
              noValidate
          >
            <CardHeader
                title={edit ?
                    'Edit Payment Agreements' :
                    'Create Payment Agreements'}
            />
            <Divider/>
            <CardHeader
                subheader="Slices information"
            />
            <Divider/>
            <CardContent>
              <Grid
                  container
                  spacing={3}
              >
                <Grid
                    item
                    md={3}
                    xs={12}
                >
                  <TextField
                      fullWidth
                      label="Number of Slices"
                      name="slices"
                      onChange={handleSlicesChange}
                      type="number"
                      required
                      value={slicesValues.slices || ''}
                      variant="outlined"
                  />
                </Grid>
                <Grid
                    item
                    md={3}
                    xs={12}
                >
                  <TextField
                      fullWidth
                      label="Amount"
                      name="totalamount"
                      onChange={handleSlicesChange}
                      type="number"
                      required
                      value={slicesValues.totalamount}
                      variant="outlined"
                  />
                </Grid>
                <Grid
                    item
                    md={3}
                    xs={12}
                >
                  <TextField
                      fullWidth
                      name="endDate"
                      onChange={handleSlicesChange}
                      type="date"
                      required
                      value={slicesValues.endDate || ''}
                      variant="outlined"
                  />
                </Grid>
                <Grid item
                      md={3}
                      xs={12}>
                  <Button color="primary" variant="contained"
                          onClick={() => AddSlices()}>
                    ADD SLICES
                  </Button>
                </Grid>

                {
                  values.slices ?
                      (
                          values.slices.map((slice, index) => {
                                return (
                                    <Grid
                                        container
                                        spacing={3}
                                        key={index}
                                    >
                                      <Grid
                                          item
                                          md={1}
                                          xs={4}
                                      >
                                        <Chip label={`Slice : ${index + 1}`}
                                              variant="outlined" color="primary"
                                              style={{marginLeft: 20}}>
                                          Slice </Chip>

                                      </Grid>
                                      <Grid
                                          item
                                          md={2}
                                          xs={4}
                                      >
                                        <TextField
                                            fullWidth
                                            name="amounts"
                                            label="Amount"
                                            type="number"
                                            value={slice.amounts ?
                                                slice.amounts :
                                                slice.amount}
                                            onChange={(e) => handleFieldsChange(e,
                                                index)}
                                            variant="outlined"

                                        />
                                      </Grid>
                                      <Grid item
                                            md={2}
                                            xs={4}>
                                        <TextField
                                            fullWidth
                                            name="start_date"
                                            onChange={(e) => handleFieldsChange(e,
                                                index)}
                                            type="date"
                                            required
                                            value={slice.start_date &&
                                              moment(slice.start_date).
                                                  format('YYYY-MM-DD').
                                                  toString()}
                                            variant="outlined"
                                        />
                                      </Grid>
                                      <Grid item
                                            md={2}
                                            xs={4}>
                                        <TextField
                                            fullWidth
                                            name="validities"
                                            onChange={(e) => handleFieldsChange(e,
                                                index)}
                                            type="date"
                                            required
                                            value={slice.validities ? slice.validities :
                                                moment(slice.validity_date).
                                                    format('YYYY-MM-DD').
                                                    toString()}
                                            variant="outlined"
                                        />
                                      </Grid>
                                      <Grid
                                          item
                                          md={1}
                                          xs={4}
                                      >
                                        <FormControl component="fieldset">
                                          <FormLabel component="legend"
                                                     hidden={index !=
                                                     0}>Paid</FormLabel>

                                          <Checkbox
                                              defaultChecked
                                              name='paid'
                                              inputProps={{'aria-label': 'secondary checkbox'}}
                                              onChange={(e) => handleSliceCheckChange(
                                                  e,
                                                  index, (slice && slice.checks &&
                                                      slice.checks.id))}
                                              checked={slice.paid == '1' ?
                                                  true :
                                                  false}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid
                                          item
                                          md={1}
                                          xs={4}
                                      >
                                        <FormControl component="fieldset">
                                          <FormLabel component="legend"
                                                     hidden={index !=
                                                     0}>Check</FormLabel>
                                          <Checkbox
                                              defaultChecked
                                              name='is_check'
                                              inputProps={{'aria-label': 'secondary checkbox'}}
                                              checked={slice.is_check == '1' ? true: false}

                                              /*checked={(slice.is_check == true || slice.checks != null) ?
                                                  true :
                                                  false}*/
                                              onChange={(e) => handleSliceCheckChange(
                                                  e,
                                                  index, (slice && slice.checks &&
                                                      slice.checks.id))}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid item
                                            md={1}
                                            xs={4}>
                                        {slice.is_check == true &&
                                        slice.checks != null  &&
                                        <FormControl component="fieldset">
                                          <FormLabel component="legend">
                                            <IconButton
                                                aria-label="more"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                onClick={(event) => updateCheck(
                                                    event, index)}
                                            >
                                              <EditIcon hidden={true}
                                              />
                                            </IconButton>
                                          </FormLabel>
                                        </FormControl>
                                        }
                                      </Grid>
                                    </Grid>
                              );
                              },
                          )
                      ) : (
                          null
                      )
                }
              </Grid>
            </CardContent>
            <Divider/>
            <CardHeader
                subheader="User Informations"
            />
            <Divider/>
            <Divider/>
            <CardContent>
              <Grid
                  container
                  spacing={3}
              >
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                  <AsyncPaginate
                      loadOptions={getUsers}
                      value={values.user}
                      onChange={userChange}
                      getOptionLabel={(option) => '[' + option.id + ']' + ' ' +
                          option.name + ' ' + option.last_name +' '+ (option?.phone ? option.phone : '')+' '+ (option?.email ? '['+option?.email+']' : '')}
                      getOptionValue={(option) => option.id}
                      placeholder="User"
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: '53px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#000',
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector('body')}
                  />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                  <AsyncPaginate
                      loadOptions={getOffers}
                      value={values?.offer}
                      onChange={offerChange}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder="Offer"
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: '53px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#000',
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector('body')}
                  />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                  <TextField
                      fullWidth
                      label="Quantity"
                      name="quantity"
                      onChange={handleChange}
                      type="number"
                      InputProps={{
                        inputProps: {
                          max: 12, min: 0, step: '1',
                        },
                      }}
                      required
                      value={values.quantity || ''}
                      variant="outlined"
                  />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                  <LocalizationProvider dateAdapter={momentAdapter}>
                    <DatePicker
                        label="Start Date"
                        name="start_date"
                        inputFormat="DD/MM/yyyy"
                        value={values?.start_date}
                        onChange={startDateChange}
                        renderInput={(props) => <TextField
                            {...props}
                            fullWidth
                            variant="outlined"
                        />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                  <LocalizationProvider dateAdapter={momentAdapter}>
                    <DatePicker
                        label="End Date"
                        name="end_date"
                        inputFormat="DD/MM/yyyy"
                        value={values?.end_date}
                        onChange={endDateChange}
                        renderInput={(props) => <TextField
                            {...props}
                            fullWidth
                            variant="outlined"
                        />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Active</FormLabel>
                    <RadioGroup aria-label="Active" name="active"
                                onChange={handleChange}
                                value={values.active || ''} row>
                      <FormControlLabel control={<Radio/>} value="1"
                                        label="Yes"/>
                      <FormControlLabel control={<Radio/>} value="0"
                                        label="No"/>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

            </CardContent>
            <Divider/>
            <CardActions>
              <Button
                  color="primary"
                  variant="contained"
                  onClick={(event) => handleSubmit(event)}>
                Save details
              </Button>
            </CardActions>
          </form>
        </Card>
        <SnackBar open={open} message={message} severity={severity}
                  handleClose={handleClose}/>
        <LoadingApi open={loading} onClick={handleCloseLoading}/>
        <Dialog
            open={openCheckModal}
            onClose={handleCheckClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle
              id="alert-dialog-title">{`Check of slice number :  ${checkSliceNumber +
          1}`}</DialogTitle>
          <DialogContent>
            <TextField
                autoFocus
                fullWidth
                select
                name="office_id"
                id="office_id"
                label="Office"
                value={(checks &&(checks.office_id ? checks.office_id : (checks.office && checks.office.id ))) || ''}
                onChange={handleCheckChange}
            >
              {
                offices.map((office) => {
                  return (
                      <MenuItem key={office.id} value={office.id}>{office.name}</MenuItem>);
                })
              }
            </TextField>
            <TextField
                autoFocus
                fullWidth
                select
                name="agency_id"
                id="agency_id"
                label="Agence"
                value={(checks && checks.agency_id) || ''}
                onChange={handleCheckChange}
            >
              {
                agencies.map((agency) => {
                  return (
                      <MenuItem key={agency.id} value={agency.id}>{agency.fr_name}</MenuItem>);
                })
              }
            </TextField>
            <TextField
                autoFocus
                margin="dense"
                name="num_check"
                id="num_check"
                label="Check Number"
                value={(checks && checks.num_check) || ''}
                onChange={handleCheckChange}
                fullWidth
            />
            <TextField
                autoFocus
                margin="dense"
                name="fullname"
                id="fullname"
                label="FullName"
                value={(checks && checks.fullname) || ''}
                onChange={handleCheckChange}
                fullWidth
            />
            <TextField
                fullWidth
                label="Phone Number"
                margin="dense"
                name="phone"
                id="phone"
                onChange={handleCheckChange}
                type="number"
                value={(checks && checks.phone) || ''}
            />
            <TextField
                autoFocus
                margin="dense"
                name="check_date"
                id="check_date"
                label="check date"
                value={(checks && checks.check_date) ? moment(checks.check_date).
                    format('YYYY-MM-DD').
                    toString() : null}
                onChange={dateChange}
                fullWidth
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCheckClose} color="primary">
              Close
            </Button>
            <Button
                onClick={(event) => handleSubmitCheck(event, checkSliceNumber)}
                color="primary" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
loading: PropTypes.bool,
};

export default Form;
